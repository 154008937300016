import { NotificationVariant } from "@hid-galaxy-ui/galaxy-react/components/Notification/notificationEnums";
import { Iconography } from "@hid-galaxy-ui/galaxy-react";
import { IconographySizes } from "@hid-galaxy-ui/galaxy-react/components/Iconography/iconEnums";
import { setGlobalToasts } from "../reducers/userInfoReducer";
import { ReactElement } from "react";

export const buildNotification = (status: string, message: string, content?:ReactElement|string, data?:string) => {
  return {
    id: status,
    message: message,
    variant:
      status === "Error" ||status==="ErrorWarn"
        ? NotificationVariant.Error
        : NotificationVariant.Success,
    icon: Iconography({
      icon: status === "Error" ? "circleX" :status === "ErrorWarn"?"circleExclamation": "circleCheck",
      size: IconographySizes.Medium,
    }),
    ...(content && { content }),  ...(data && { data }),
  };
};

const notficationVarientMapper: any = {
  EXPIRED: NotificationVariant.Warning,
  PENDING: NotificationVariant.Info,
  FAILED: NotificationVariant.Error,
  SUCCESS: NotificationVariant.Success,
};
export const NotificationWarning = (
  message: string,
  icon: string,
  notifcationType: string,
  title?: string,
  key = `notification-${notifcationType}`
) => {
  return [
    {
      id: key,
      ...(title && { title }),
      ...(title && { expanded: true }),
      message: message,
      variant: notficationVarientMapper[notifcationType],
      icon: Iconography({
        icon: icon,
        size: IconographySizes.Medium,
      }),
    },
  ];
};

export const NotificationSuccess = (
  message: string | number ,
  icon: string,
  notifcationType: string,
  title?: string,
  key = `notification-${notifcationType}`
) => {
  return [
    {
      id: key,
      ...(title && { title }),
      ...(title && { expanded: true }),
      message: message,
      variant: notficationVarientMapper[notifcationType],
      icon: Iconography({
        icon: icon,
        size: IconographySizes.Medium,
      }),
    },
  ];
};
export const showDeviceBusyNotification=(dispatch:any,message:string)=>{
  return dispatch(
    setGlobalToasts([
      buildNotification("Error", message),
    ])
  );
}
